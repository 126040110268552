import React, {useState, useEffect} from 'react'
import {Form, Input, Button, Modal, Select, message} from 'antd'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {createUpdateGroupAff, getAffiliate} from '../../../setup/redux/service/groupAffService'

export interface Props {
  isModalOpen: boolean
  setIsModalOpen: any
  recordModal: any
  setRecordModal: any
  refresh: () => void
}

const ModalCUAff = ({isModalOpen, setIsModalOpen, recordModal, setRecordModal, refresh}: Props) => {
  const intl = useIntl()
  let location = useLocation()
  const formRef = React.createRef<any>()
  const [affiliateSelect, setAffiliateSelect] = useState<any>([])

  useEffect(() => {
    ;(async () => {
      getData()
    })()
  }, [])

  const getData = async () => {
    const dataGr = await getAffiliate('')
    const formatSelect = dataGr?.rows?.map((value: {id: number; email: string; mobile: string}) => {
      return {
        value: value?.email || value?.mobile,
        label: value?.email || value?.mobile,
      }
    })
    setAffiliateSelect(formatSelect)
  }

  const handleOk = async () => {
    let messageSuccess = 'Thêm mới thành công'
    let dataPayload = {
      aff_account: formRef.current.getFieldValue('aff_acc'),
      group_id: Number(location.pathname.split('/')[3]),
    }

    await createUpdateGroupAff(dataPayload)
    message.success(messageSuccess)
    setIsModalOpen(false)
    refresh()
  }

  const handleCancel = () => {
    setRecordModal({})
    setIsModalOpen(false)
  }
  useEffect(() => {
    if (recordModal?.del === 0) {
      const obj_form = {
        name: recordModal?.name,
        grr_aff: recordModal?.grr_aff?.name,
      }
      formRef.current.setFieldsValue(obj_form)
    }
  }, [recordModal])

  return (
    <Modal
      title='Thêm mới'
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {intl?.formatMessage({id: 'BTN.CANCEL'})}
        </Button>,
        <Button key='submit' type='primary' onClick={handleOk}>
          {intl?.formatMessage({id: 'BTN.ADD'})}
        </Button>,
      ]}
    >
      <Form ref={formRef} style={{marginTop: '20px'}}>
        <div className='form_container row'>
          <div className='col-sm-12'>
            <div className='customLabel'>
              Affiliate
              <span className='customRequired'>*</span>
            </div>
            <Form.Item name='aff_acc'>
              <Select options={affiliateSelect} placeholder='Chọn affiliate...' />
            </Form.Item>
          </div>

          {/* <div className='col-sm-12'>
            <div className='customLabel'>
              Nhóm affiliate
              <span className='customRequired'>*</span>
            </div>
            <Form.Item name='grr_aff'>
              <Select placeholder='Chọn nhóm affiliate...' options={dataSelect} />
            </Form.Item>
          </div> */}
        </div>
      </Form>
    </Modal>
  )
}

export default ModalCUAff
