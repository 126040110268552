import {http} from 'src/setup/axios/SetupAxios'

// *************** Group_Affiliate ********************
export const getGroup = async (params: string) => {
  try {
    const res = await http.get(`aff/groups`)

    return res?.data?.data
  } catch (error) {
    return error
  }
}

export const createUpdateGroup = async (dataPayload: any) => {
  try {
    const res = await http.post(`aff/add-update-group`, dataPayload)

    return res?.data
  } catch (error) {
    return error
  }
}

export const deleteGroup = async (id: number) => {
  try {
    const res = await http.post(`aff/remove-groups/${id}`)

    return res?.data
  } catch (error) {
    return error
  }
}

// ***************** Affiliate ********************

export const getGroupAff = async (group_id: string) => {
  try {
    const res = await http.get(`aff/group-aff?group_id=${group_id}`)

    return res?.data?.data
  } catch (error) {
    return error
  }
}

export const getAffiliate = async (params: string) => {
  try {
    const res = await http.get(`aff/child`)

    return res?.data?.data
  } catch (error) {
    return error
  }
}

export const createUpdateGroupAff = async (dataPayload: any) => {
  try {
    const res = await http.post(`aff/add-groups-aff`, dataPayload)

    return res?.data
  } catch (error) {
    return error
  }
}

export const deleteGroupAff = async (aff_id: number) => {
  try {
    const res = await http.post(`aff/remove-groups-aff`, {aff_id: aff_id})

    return res?.data
  } catch (error) {
    return error
  }
}
