import axios from 'axios'

export const http = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  timeout: 1000000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Token: `${localStorage.getItem('token')}` || '',
  },
})

http.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      Token: `${localStorage.getItem('token')}` || '',
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
