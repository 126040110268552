/* tslint:disable:no-unused-variable */
import React, {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import ApexCharts from 'react-apexcharts'

import {formatMoney} from '../../libs/money'
import {getDataDashboard} from '../../../setup/redux/service/dashboard'
import {useLocation} from 'react-router-dom'

import {StatisticsWidget5} from '../../.././_metronic/partials/widgets'
import {KTSVG} from 'src/_metronic/helpers'
import dayjs from 'dayjs'
import {DatePicker, Space} from 'antd'
const {RangePicker} = DatePicker

const DashboardAdmin: FC = () => {
  const location = useLocation()
  const intl = useIntl()
  const date: Date = new Date()
  const [dataTotal, setTotal] = useState<any>({})
  const [isCopy, setIsCopy] = useState<boolean>(false)
  const [url, setUrl] = useState<string>(
    'http://localhost:3000/dashboard/http://localhost:3000/dashboard'
  )

  useEffect(() => {
    // loadData(moment().startOf('months').format('DD/MM/YYYY'), moment().startOf('days').format('DD/MM/YYYY'))
  }, [])

  const loadData = async (start: any, end: any) => {
    const resp = await getDataDashboard({
      start,
      end,
    })
    console.log('=========', resp)
  }

  const data = {
    series: [
      {
        name: 'Doanh thu',
        data: [
          45000000, 52000000, 38000000, 24000000, 33000000, 26000000, 21000000, 20000000, 6000000,
          8000000, 15000000, 10000000,
        ],
      },
      {
        name: 'Hoa hồng trực tiếp',
        data: [
          35000000, 41000000, 62000000, 42000000, 13000000, 18000000, 29000000, 37000000, 36000000,
          51000000, 32000000, 35000000,
        ],
      },
      {
        name: 'Hoa hồng hệ thống',
        data: [
          87000000, 57000000, 74000000, 99000000, 75000000, 38000000, 62000000, 47000000, 82000000,
          56000000, 45000000, 47000000,
        ],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [5, 7, 5],
        curve: 'straight',
        dashArray: [0, 8, 5],
      },
      title: {
        text: 'Biểu đồ thống kê',
        align: 'left',
      },
      legend: {
        show: true,
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: [
          'Tháng 1',
          'Tháng 2',
          'Tháng 3',
          'Tháng 4',
          'Tháng 5',
          'Tháng 6',
          'Tháng 7',
          'Tháng 8',
          'Tháng 9',
          'Tháng 10',
          'Tháng 11',
          'Tháng 12',
        ],
      },
      tooltip: {
        y: [
          {
            formatter: (value: any) => {
              return value?.toLocaleString('it-IT', {style: 'currency', currency: 'VND'})
            },
          },
          {
            formatter: (value: any) => {
              return value?.toLocaleString('it-IT', {style: 'currency', currency: 'VND'})
            },
          },
          {
            formatter: (value: any) => {
              return value?.toLocaleString('it-IT', {style: 'currency', currency: 'VND'})
            },
          },
        ],
      },
      grid: {
        borderColor: '#f1f1f1',
      },
      yaxis: {
        labels: {
          formatter: function (value: any) {
            return value?.toLocaleString('it-IT', {style: 'currency', currency: 'VND'})
          },
        },
      },
    },
  }

  const handleFilterTime = (e: any) => {
    const data: {
      startTime: string
      endTime: string
    } = {
      startTime: e?.length ? e[0]['$d'].toISOString() : '',
      endTime: e?.length ? e[1]['$d'].toISOString() : '',
    }

    console.log(data)
  }

  const dataTutorial = [
    {title: '1. Sao chép đường dẫn.'},
    {title: '2. Chia sẻ cho bạn bè hoặc người khác.'},
    {title: '3. Nhận tiền từ đăng ký của họ.'},
  ]
  return (
    <>
      {/* begin::Row */}
      <div className='card px-5 py-5 mb-5'>
        <div className='title_flex'>
          <div className='filter_flex'>
            <div className='filter_time'>
              <KTSVG
                path='/media/icons/duotune/general/gen031.svg'
                className='svg-icon-5 svg-icon-gray-500 me-1 fz-16'
              />
              <span>Lọc theo thời gian : {'  '}</span>
            </div>
            <div>
              <RangePicker
                onChange={(e) => handleFilterTime(e)}
                // defaultValue={[dayjs(date, 'DD/MM/YYYY'), dayjs(date, 'DD/MM/YYYY')]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='card px-5 py-5 mb-5'>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/svg/icons/Shopping/Dollar.svg'
              color='danger'
              iconColor='white'
              title={`${formatMoney(dataTotal.totalRevenue || 0)} VND`}
              // description={intl?.formatMessage({id: 'DASHBOARD.SALES'})}
              description='Doanh thu'
            />
          </div>

          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/svg/icons/Shopping/Wallet2.svg'
              color='primary'
              iconColor='white'
              title={`${formatMoney(dataTotal.totalCommission || 0)} VND`}
              // description={intl?.formatMessage({id: 'DASHBOARD.COMMISSION'})}
              description='Hoa hồng'
            />
          </div>

          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/svg/icons/Shopping/Wallet3.svg'
              color='success'
              iconColor='white'
              title={`${formatMoney(dataTotal.totalChild || 0)} VND`}
              // description={intl?.formatMessage({id: 'DASHBOARD.COMMISSION_SYSTEM'})}
              description='Số lượng Affiliate'
            />
          </div>
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/svg/icons/Shopping/Chart-bar2.svg'
              color='warning'
              iconColor='white'
              title={`${formatMoney(dataTotal.totalChild || 0)} VND`}
              // description={intl?.formatMessage({id: 'DASHBOARD.COMMISSION_SYSTEM'})}
              description='Số lượng đơn hàng'
            />
          </div>
        </div>
      </div>
      <div className='card px-5 py-5 mb-5'>
        <div className='row gy-5 g-xl-8'>
          <div className='col-xxl-12'>
            <ApexCharts options={data?.options} series={data.series} type='line' height={350} />
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardAdmin
