/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from 'src/_metronic/helpers'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {searchAffChild} from 'src/setup/redux/service/ordersService'
import {useNavigate} from 'react-router-dom'
import { formatTime } from '../../../libs/money'
const loginSchema = Yup.object().shape({
  code: Yup.string().max(50, 'Maximum 50 symbols').required('Code is required'),
})

const initialValues = {
  code: '',
}

const Partner = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [dataTable, setDataTable] = useState([])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      console.log(values)
      getSearchOrders(values)
    },
  })

  const getSearchOrders = async (code: any) => {
    const params = `limit=${10}&page=${1}&code=${code}`
    const data = await searchAffChild(params)
    setDataTable(data?.data?.rows)
  }

  useEffect(() => {
    getSearchOrders('')
  }, [])

  return (
    <div>
      <div className={`card mb-5 mb-xl-8 `}>
        {/* begin::Header */}
        <div className='card-body border-0 pt-5 mb-5' style={{display: 'flex'}}>
          <div>
            <label className='form-label fs-6 fw-bolder text-dark'>
              DANH SÁCH AFFILIATE TUYẾN DƯỚI
            </label>
            {/* <input
              placeholder='Mã code..'
              {...formik.getFieldProps('code')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.code && formik.errors.code},
                {
                  'is-valid': formik.touched.code && !formik.errors.code,
                }
              )}
              type='code'
              name='code'
              autoComplete='off'
            />
            {formik.touched.code && formik.errors.code && (
              <div className='fv-plugins-message-container position-absolute text-danger top-10'>
                <span role='alert'>{formik.errors.code}</span>
              </div>
            )} */}
          </div>
          {/* <div className='ms-5'>
            <div className='form-label fs-6 fw-bolder text-dark mb-4'>&nbsp;</div>
            <a href='#' className='btn btn-sm btn-primary' onClick={formik.submitForm}>
              Tìm kiếm
            </a>
          </div> */}
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted bg-light'>
                  <th className='ps-4 min-w-200px rounded-start'>Mã</th>
                  <th className='min-w-200px'>Họ tên</th>
                  <th className='min-w-200px'>Email</th>
                  <th className='min-w-200px'>SĐT</th>
                  <th className='min-w-200px'>Thời gian</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {dataTable?.map((value: any, index: number) => {
                  return (
                    <tr>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                        >
                          {value?.code}
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                        >
                          {value?.name}
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                        >
                          {value?.email}
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                        >
                          {value?.mobile}
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                        >
                          {formatTime(value?.createdAt)}
                        </a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </div>
  )
}

export default Partner
