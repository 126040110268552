/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
// import {PrivateRoutes} from './PrivateRoutes'
// import {Logout, AuthPage} from '../modules/auth'
// import {ErrorsPage} from '../modules/errors/ErrorsPage'
import PublicRouting from './PublicRouting/PublicRouting'
import PublicRoute from './PublicRouting/PublicRoute'

import PrivateRouting from './PrivateRouting/PrivateRouting'
import PrivateRoute from './PrivateRouting/PrivateRoute'
import {Error404} from '../../app/modules/errors/components/Error404'

const Routeses: FC = () => {
  const isAuthorized = useSelector<any>(({auth}) => auth?.user, shallowEqual)

  return (
    <Router>
      <Routes>
        <Route path='/' element={<PublicRoute />}>
          {PublicRouting.map((publicRoute, index) => {
            const {path, Component} = publicRoute
            return <Route key={index} path={path} element={<Component />} />
          })}
        </Route>

        <Route path='/' element={<PrivateRoute />}>
          {PrivateRouting.map((privateRoute: any, index: number) => {
            const {path, Component} = privateRoute
            return (
              <Route
                key={index}
                path={path}
                element={
                  <>
                    <MasterLayout>
                      <Component />
                    </MasterLayout>
                  </>
                }
              />
            )
          })}
        </Route>
        <Route path='/' element={<Navigate to='/auth/login' replace />} />
        <Route path='*' element={<Error404 />} />
      </Routes>
    </Router>
  )
}

export default Routeses

// <Switch>
//   {!isAuthorized ? (
//     /*Render auth page when user at `/auth` and not authorized.*/
//     <Route>
//       <AuthPage />
//     </Route>
//   ) : (
//     /*Otherwise redirect to root page (`/`)*/
//     <Redirect from='/auth' to='/' />
//   )}

//   <Route path='/error' component={ErrorsPage} />
//   <Route path='/logout' component={Logout} />

//   {!isAuthorized ? (
//     /*Redirect to `/auth` when user is not authorized*/
//     <Redirect to='/auth/login' />
//   ) : (
//     <MasterLayout>
//       <PrivateRoutes />
//     </MasterLayout>
//   )}
// </Switch>
