/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from 'src/_metronic/helpers'
import {Segmented} from 'antd'
import {searchOrder, getPackage} from 'src/setup/redux/service/ordersService'
import {useIntl} from 'react-intl'
import {DatePicker, Select, message, Form} from 'antd'
import { formatMoney, formatTime } from '../../libs/money'

const TableNearCommis: FC<{
  path: string
}> = ({path = '/orders/commission'}) => {
  const intl = useIntl()
  const form = useRef<any>(null)
  const [dataTable, setDataTable] = useState<[]>([])
  const [dataPackage, setDataPackage] = useState<[]>([])

  const getSearchOrders = async (param: any) => {
    const data = await searchOrder(param)
    console.log('=======', data);
    setDataTable(data?.data?.rows)
  }

  useEffect(() => {
    ;(async () => {
      getSearchOrders({})
      const data = await getPackage('')
      if (data?.code !== 200) {
        return message.error('Gọi gói thất bại !')
      }
      const format = data?.data?.map((value: {id: number; name: string}) => {
        return {
          value: value?.id,
          label: value?.name,
        }
      })
      setDataPackage(format)
    })()
  }, [])

  const handleSubmit = async () => {
    const data = await form?.current?.getFieldsValue()
    const time = {
      startTime: data?.time?.length ? data?.time[0]['$d'].toISOString() : '',
      endTime: data?.time?.length ? data?.time[1]['$d'].toISOString() : '',
    }
    let params = `limit=${10}&page=${1}&code=${data?.code || ''}&start=${time?.startTime}&end=${
      time?.endTime
    }&status=${data?.status || ''}&package_id=${data?.package || ''}`

    await getSearchOrders(params)
  }

  return (
    <div>
      <div className={`card mb-5 mb-xl-8 `}>
        <span className='card-label fw-bold fs-2 mb-3 pt-10 px-10'>Đơn hàng hoa hồng gần nhất</span>

        {/* Table */}
        <div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table
                className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
                style={{overflow: 'scroll'}}
              >
                <thead>
                  <tr className='fw-bolder text-muted bg-light'>
                    <th className='ps-4 min-w-215px rounded-start'>Khách hàng</th>
                    <th className='min-w-200px'>Gói</th>
                    <th className='min-w-150px'>Tổng tiền</th>
                    <th className='min-w-125px'>Hoa hồng</th>
                    <th className='min-w-100px'>Trạng thái</th>
                    <th className='min-w-100px'>Thời gian</th>
                    
                  </tr>
                </thead>

                <tbody>
                  {dataTable.map((value: any, index: number) => {
                    return (
                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
                              >
                                {value?.customer.name}
                              </a>
                              <span className='text-muted fw-bold text-muted d-block fs-7'>
                                {value?.customer.email}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                          >
                            {value?.packageData?.name}
                          </a>
                          <span className='text-muted fw-bold text-muted d-block fs-7'>
                            Giá gói:{' '}
                            {formatMoney(value?.packageData?.price)}
                          </span>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6 '
                          >
                            {formatMoney(value?.total_price)}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                          >
                            {formatMoney(value?.commission)}
                          </a>
                        </td>

                        

                        <td>
                          <span
                            className={
                              value?.status === 1
                                ? 'badge badge-light-success fs-7 fw-bold'
                                : 'badge badge-light-danger fs-7 fw-bold'
                            }
                          >
                            {value?.status === 1 ? 'Thành công' : (value?.status === 0 ? 'Chờ xử lý' : 'Thất bại')}
                          </span>
                        </td>
                        <td>
                          <span className='badge badge-light-primary fs-7 fw-bold'>
                          { formatTime(value?.createdAt) }
                          </span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableNearCommis
