import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword, requestResetPassword} from '../redux/AuthCRUD'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required('Vui lòng nhập email'),
})

const resetPasswordSchema = Yup.object().shape({
  otp: Yup.string()
    .required('Vui lòng nhập otp'),
  password: Yup.string()
    .required('Vui lòng nhập mật khẩu'),
})

export function ForgotPassword() {
  const [stepForgot, setStep] = useState(1)
  const [tokenReset, setToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({data}) => {
            setLoading(false)
            if (data && data.data && data.data.token) {
              setToken(data.data.token)
              setStep(2)
            } else {
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
              setStatus(data?.data?.message || 'Thông tin không chính xác')
            }
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('Thông tin không chính xác')
          })
      }, 1000)
    },
  })

  const formik2 = useFormik({
    initialValues: {
      otp: '',
      password: ''
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestResetPassword(values.otp, values.password, tokenReset)
          .then(({data}) => {
            setLoading(false)
            if (data && data.data && data.data.aff_id) {
              setHasErrors(false)
            } else {
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
              setStatus(data?.data?.message || 'Thông tin không chính xác')
            }
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('Thông tin không chính xác')
          })
      }, 1000)
    },
  })

  return (
    <>
      <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          { stepForgot === 1 && (
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_password_reset_form'
              onSubmit={formik.handleSubmit}
            >
              <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark mb-3'>Quên mật khẩu ?</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-400 fw-bold fs-4'>
                  Nhập email để lấy lại mật khẩu.
                </div>
                {/* end::Link */}
              </div>

              {/* begin::Title */}
              {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>
                    Sorry, looks like there are some errors detected, please try again.
                  </div>
                </div>
              )}
              {/* end::Title */}

              {/* begin::Form group */}
              <div className='fv-row mb-10'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
                <input
                  type='email'
                  placeholder=''
                  autoComplete='off'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button
                  type='submit'
                  id='kt_password_reset_submit'
                  className='btn btn-lg btn-primary fw-bolder me-4'
                >
                  {!loading ? (
                    <span className='indicator-label'>Submit</span>
                  ) : (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <Link to='/auth/login'>
                  <button
                    type='button'
                    id='kt_login_password_reset_form_cancel_button'
                    className='btn btn-lg btn-light-primary fw-bolder'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    Cancel
                  </button>
                </Link>{' '}
              </div>
              {/* end::Form group */}
            </form>
          )}

          { stepForgot === 2 && (
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_password_reset_form_2'
              onSubmit={formik2.handleSubmit}
            >
              <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark mb-3'>Quên mật khẩu ?</h1>
                {/* end::Title */}

                {/* begin::Link */}
                { hasErrors !== false && (
                  <div className='text-gray-400 fw-bold fs-4'>
                    Nhập mã xác thực (OTP) gửi tới email và mật khẩu mới để cập nhật lại mật khẩu
                  </div>
                )}
                
                {/* end::Link */}
              </div>

              {/* begin::Title */}
              {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>
                    Sorry, looks like there are some errors detected, please try again.
                  </div>
                </div>
              )}

              {hasErrors === false && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                  <div className='text-info'>Cập nhật mật khẩu thành công, bạn vui lòng sử dụng mật khẩu mới để đăng nhập lại.</div>
                </div>
              )}
              {/* end::Title */}

              {hasErrors !== false ? (
                <>
                  {/* begin::Form group */}
              <div className='fv-row mb-10'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Mã xác thực</label>
                <input
                  type='text'
                  placeholder='Mã xác thực'
                  autoComplete='off'
                  {...formik2.getFieldProps('otp')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik2.touched.otp && formik2.errors.otp},
                    {
                      'is-valid': formik2.touched.otp && !formik2.errors.otp,
                    }
                  )}
                />
                {formik2.touched.otp && formik2.errors.otp && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik2.errors.otp}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-10'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Mật khẩu mới</label>
                <input
                  placeholder='Password'
                  type='password'
                  autoComplete='off'
                  {...formik2.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik2.touched.password && formik2.errors.password,
                    },
                    {
                      'is-valid': formik2.touched.password && !formik2.errors.password,
                    }
                  )}
                />
                {formik2.touched.password && formik2.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik2.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button
                  type='submit'
                  id='kt_password_reset_submit'
                  className='btn btn-lg btn-primary fw-bolder me-4'
                >
                  {!loading ? (
                    <span className='indicator-label'>Submit</span>
                  ) : (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <Link to='/auth/login'>
                  <button
                    type='button'
                    id='kt_login_password_reset_form_cancel_button'
                    className='btn btn-lg btn-light-primary fw-bolder'
                    disabled={formik2.isSubmitting || !formik2.isValid}
                  >
                    Cancel
                  </button>
                </Link>{' '}
              </div>
              {/* end::Form group */}
                </>
              ) : (
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                  <Link to='/auth/login'>
                    <button
                      type='button'
                      id='kt_login_password_reset_form_cancel_button'
                      className='btn btn-lg btn-light-primary fw-bolder'
                      disabled={formik2.isSubmitting || !formik2.isValid}
                    >
                      Đăng nhập
                    </button>
                  </Link>{' '}
                </div>
              )}

              
            </form>
          )}
          
        </div>
      </div>
    </>
  )
}
