import React, {useState, useEffect} from 'react'
import {Button, Modal, Form, Select, Input, message} from 'antd'
import {getListBank} from '../../../setup/redux/service/paymentService'
import {useIntl} from 'react-intl'
import {http} from 'src/setup/axios/SetupAxios'

interface Props {
  isModalOpen: boolean
  setIsModalOpen: any
  user?: any
  getListBanks?: any
  valueUpdate?: any
}

const ModalAddPayment = ({isModalOpen, setIsModalOpen, user, getListBanks, valueUpdate}: Props) => {
  const [listBank, setListBank] = useState<[]>([])
  const formRef = React.createRef<any>()
  const intl = useIntl()

  const handleOk = async () => {
    await formRef.current.validateFields()
    const {name, bank_branch, acc_num, acc_name} = formRef.current.getFieldsValue()
    const dataPayLoad = {
      id: valueUpdate?.length > 0 ? valueUpdate[0].id : null,
      bank_id: name,
      bank_branch: bank_branch,
      bank_account: acc_num,
      bank_owner: acc_name,
    }
    try {
      const res: any = await http.post('/aff/add-update-bank', dataPayLoad)
      if (res?.data?.code !== 200) {
        return message.error('Thêm phương thức không thành công, có lỗi xảy ra !')
      }
      await setIsModalOpen(false)
      await getListBanks()
      await formRef?.current?.resetFields()
    } catch (error) {
      return message.error('Thêm phương thức không thành công, có lỗi xảy ra !')
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    ;(async () => {
      const data = await getListBank('')
      setListBank(data)
    })()
  }, [])

  useEffect(() => {
    if (valueUpdate?.length > 0) {
      formRef?.current?.setFieldsValue({
        name: valueUpdate[0]?.bank_id,
        bank_branch: valueUpdate[0]?.bank_branch,
        acc_num: valueUpdate[0]?.bank_account,
        acc_name: valueUpdate[0]?.bank_owner,
      })
    }
  }, [valueUpdate])

  return (
    <>
      <Modal
        title={intl?.formatMessage({id: 'PAYMENT.ADD.TITLE'})}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <a key='back' onClick={handleCancel} className='btn btn-sm btn-light-primary mx-3'>
            {intl?.formatMessage({id: 'BTN.CANCEL'})}
          </a>,
          <a key='submit' type='primary' onClick={handleOk} className='btn btn-sm btn-primary mx-3'>
            {intl?.formatMessage({id: valueUpdate?.length > 0 ? 'BTN.UPDATE' : 'BTN.ADD'})}
          </a>,
        ]}
        centered
        width={650}
        className='modal_bank'
      >
        <Form ref={formRef} style={{marginTop: '20px'}}>
          <div className='form_container row'>
            <div className='col-sm-6'>
              <div className='customLabel'>
                {intl?.formatMessage({id: 'PAYMENT.ADD.NAME'})}
                <span className='customRequired'>*</span>
              </div>
              <Form.Item
                name='name'
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject('Tên ngân hàng không được bỏ trống !')
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <Select placeholder={intl?.formatMessage({id: 'PAYMENT.ADD.NAME.SELECT'})}>
                  {listBank?.map((value: any) => {
                    return (
                      <Select.Option key={value?.id} value={value?.id}>
                        {value?.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className='col-sm-6'>
              <div className='customLabel'>
                {intl?.formatMessage({id: 'PAYMENT.ADD.BRANCH'})}
                <span className='customRequired'>*</span>
              </div>
              <Form.Item
                name='bank_branch'
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value || value?.trim() === '') {
                        return Promise.reject('Chi nhánh ngân hàng không được bỏ trống !')
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <Input placeholder={intl?.formatMessage({id: 'PAYMENT.ADD.BRANCH'})} />
              </Form.Item>
            </div>
            <div className='col-sm-12'>
              <div className='customLabel'>
                {intl?.formatMessage({id: 'PAYMENT.ADD.ACCNUMBER'})}
                <span className='customRequired'>*</span>
              </div>
              <Form.Item
                name='acc_num'
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value || value?.trim() === '') {
                        return Promise.reject('Số tài khoản không được bỏ trống !')
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <Input placeholder={intl?.formatMessage({id: 'PAYMENT.ADD.ACCNUMBER.INPUT'})} />
              </Form.Item>
            </div>
            <div className='col-sm-12'>
              <div className='customLabel'>
                {intl?.formatMessage({id: 'PAYMENT.ADD.ACCNAME'})}
                <span className='customRequired'>*</span>
              </div>
              <Form.Item
                name='acc_name'
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value || value?.trim() === '') {
                        return Promise.reject('Tên tài khoản không được bỏ trống !')
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <Input placeholder={intl?.formatMessage({id: 'PAYMENT.ADD.ACCNAME'})} />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default ModalAddPayment
