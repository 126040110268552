import {configureStore} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {reduxBatch} from '@manaflair/redux-batch'
import {persistStore} from 'redux-persist'
import rootReducer from '../redux/reducer/RootReducer'
import rootSaga from './saga/RootSage'
const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({immutableCheck: false, serializableCheck: false, thunk: false}).concat(
      sagaMiddleware
    ),
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch],
})

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store)
export default store
