/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from 'src/_metronic/helpers'
import {
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from '../../accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Card5} from 'src/_metronic/partials/content/cards/Card5'
import {KTSVG} from 'src/_metronic/helpers'
import {MixedWidget11} from 'src/_metronic/partials/widgets'
import {DatePicker, Space} from 'antd'
import {getPackage} from 'src/setup/redux/service/ordersService'
const {RangePicker} = DatePicker
const profileDetailsSchema = Yup.object().shape({
  fName: Yup.string().required('First name is required'),
  lName: Yup.string().required('Last name is required'),
  company: Yup.string().required('Company name is required'),
  contactPhone: Yup.string().required('Contact phone is required'),
  companySite: Yup.string().required('Company site is required'),
  country: Yup.string().required('Country is required'),
  language: Yup.string().required('Language is required'),
  timeZone: Yup.string().required('Time zone is required'),
  currency: Yup.string().required('Currency is required'),
})

const PartnerDetails: React.FC = () => {
  const [data, setData] = useState<IProfileDetails>(initialValues)

  const updateData = (fieldsToUpdate: Partial<IProfileDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        values.communications.email = data.communications.email
        values.communications.phone = data.communications.phone
        values.allowMarketing = data.allowMarketing
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        setLoading(false)
      }, 1000)
    },
  })

  return (
    <>
      {' '}
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          data-bs-target='#kt_account_profile_details'
        >
          <div className='title_flex'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Profile Details</h3>
            </div>
            <div className='filter_flex'>
              <div className='filter_time'>
                <KTSVG
                  path='/media/icons/duotune/general/gen031.svg'
                  className='svg-icon-5 svg-icon-gray-500 me-1 fz-16'
                />
                <span>Lọc theo thời gian : {'  '}</span>
              </div>
              <div>
                <RangePicker size='small' />
              </div>
            </div>
          </div>
        </div>
        <div className='row g-6 g-xl-9' style={{marginBottom: '50px'}}>
          <div className='col-sm-6 col-xl-4'>
            <div className='card h-100'>
              <div className='card-header flex-nowrap border-0 pt-9'>
                <div className='card-title m-0'>
                  <a href='#' className='fs-4 fw-bold text-hover-primary text-gray-600 m-0'>
                    Tổng hoa hồng phát sinh
                  </a>
                </div>
              </div>

              <div className='card-body d-flex flex-column px-9 pt-6 pb-8'>
                <div className='fs-2tx fw-bolder mb-3'>259.000 VNĐ</div>

                <div className='d-flex align-items-center flex-wrap mb-5 mt-10 fs-6'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr007.svg'
                    className='svg-icon-3 me-1 svg-icon-success'
                  />

                  <div className={'fw-bolder me-2 text-success'}>+ 40%</div>
                  <div className='fw-bold text-gray-400'></div>
                </div>

                <div className='d-flex align-items-center fw-bold'>
                  <span className='badge bg-light text-gray-700 px-3 py-2 me-2'>20%</span>
                  <span className='text-gray-400 fs-7'>Decrease</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-xl-4'>
            <div className='card h-100'>
              <div className='card-header flex-nowrap border-0 pt-9'>
                <div className='card-title m-0'>
                  <a href='#' className='fs-4 fw-bold text-hover-primary text-gray-600 m-0'>
                    Tổng đơn hàng
                  </a>
                </div>
              </div>

              <div className='card-body d-flex flex-column px-9 pt-6 pb-8'>
                <div className='fs-2tx fw-bolder mb-3'>259</div>

                <div className='d-flex align-items-center flex-wrap mb-5 mt-10 fs-6'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr007.svg'
                    className='svg-icon-3 me-1 svg-icon-success'
                  />

                  <div className={'fw-bolder me-2 text-success'}>+ 40%</div>
                  <div className='fw-bold text-gray-400'></div>
                </div>

                <div className='d-flex align-items-center fw-bold'>
                  <span className='badge bg-light text-gray-700 px-3 py-2 me-2'>20%</span>
                  <span className='text-gray-400 fs-7'>Decrease</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-xl-4'>
            <div className=''>
              <MixedWidget11
                className='card-xxl-stretch-50 mb-5 mb-xl-8'
                chartColor='primary'
                chartHeight='160px'
                title='Biểu đồ tăng trưởng hoa hồng'
                data='400.000 VND'
              />
            </div>
          </div>
        </div>

        <div className='table_flex'>
          <div id='kt_account_profile_details' className='collapse show jqjnnqwn'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Ảnh</label>
                  <div className='col-lg-8'>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                    >
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        style={{backgroundImage: `url(${toAbsoluteUrl(data.avatar)})`}}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Tên khách hàng
                  </label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='First name'
                          {...formik.getFieldProps('fName')}
                        />
                        {formik.touched.fName && formik.errors.fName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.fName}</div>
                          </div>
                        )}
                      </div>

                      <div className='col-lg-6 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Last name'
                          {...formik.getFieldProps('lName')}
                        />
                        {formik.touched.lName && formik.errors.lName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.lName}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Mã sản phẩm
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Company name'
                      {...formik.getFieldProps('company')}
                    />
                    {formik.touched.company && formik.errors.company && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.company}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Giá</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='tel'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Phone number'
                      {...formik.getFieldProps('contactPhone')}
                    />
                    {formik.touched.contactPhone && formik.errors.contactPhone && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.contactPhone}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Gói sản phẩm</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Company website'
                      {...formik.getFieldProps('companySite')}
                    />
                    {formik.touched.companySite && formik.errors.companySite && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.companySite}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Địa chỉ email</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      {...formik.getFieldProps('country')}
                    />
                    {formik.touched.country && formik.errors.country && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.country}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Language</label>
                  <div className='col-lg-8 fv-row'>
                    <select
                      className='form-select form-select-solid form-select-lg'
                      {...formik.getFieldProps('language')}
                    >
                      <option value='ja'>日本語 - Japanese</option>
                      <option value='zh-cn'>简体中文 - Simplified Chinese</option>
                      <option value='zh-tw'>繁體中文 - Traditional Chinese</option>
                    </select>
                    {formik.touched.language && formik.errors.language && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.language}</div>
                      </div>
                    )}

                    <div className='form-text'>
                      Please select a preferred language, including date, time, and number
                      formatting.
                    </div>
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && 'Save Changes'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
          <div className='table-responsive' style={{marginLeft: '25px'}}>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted bg-light'>
                  <th className='ps-4 min-w-325px rounded-start'>Mã đơn hàng</th>
                  {/* <th className='min-w-125px'>Nhà phân phối</th> */}

                  <th className='min-w-200px'>Giá</th>
                  <th className='min-w-200px'>Hoa hồng phát sinh</th>
                  <th className='min-w-150px'>Trạng thái</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                          HJH558GHAB
                        </a>
                        <span className='text-muted fw-bold text-muted d-block fs-7'></span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      2.000.000 VND
                    </a>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>Số lượng: 2</span>
                  </td>

                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      500.000 VND
                    </a>
                  </td>

                  <td>
                    <span className='badge badge-light-primary fs-7 fw-bold'> Thành công</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                          HJH558GHAB
                        </a>
                        <span className='text-muted fw-bold text-muted d-block fs-7'></span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      2.000.000 VND
                    </a>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>Số lượng: 2</span>
                  </td>

                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      500.000 VND
                    </a>
                  </td>

                  <td>
                    <span className='badge badge-light-primary fs-7 fw-bold'> Thành công</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                          HJH558GHAB
                        </a>
                        <span className='text-muted fw-bold text-muted d-block fs-7'></span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      2.000.000 VND
                    </a>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>Số lượng: 2</span>
                  </td>

                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      500.000 VND
                    </a>
                  </td>

                  <td>
                    <span className='badge badge-light-primary fs-7 fw-bold'> Thành công</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                          HJH558GHAB
                        </a>
                        <span className='text-muted fw-bold text-muted d-block fs-7'></span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      2.000.000 VND
                    </a>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>Số lượng: 2</span>
                  </td>

                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      500.000 VND
                    </a>
                  </td>

                  <td>
                    <span className='badge badge-light-primary fs-7 fw-bold'> Thành công</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export {PartnerDetails}
