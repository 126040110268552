import {http} from 'src/setup/axios/SetupAxios'

export const getListBank = async (params: string) => {
  try {
    const res = await http.get(`/credit/bank-config`)

    return res?.data?.data
  } catch (error) {
    return error
  }
}

export const getRefCode = async (params: string) => {
  try {
    const res = await http.get(`aff/ref-code`)

    return res?.data?.data
  } catch (error) {
    return error
  }
}

export const changeRefCode = async (dataPayload: any) => {
  try {
    const res = await http.post(`aff/change-code`, dataPayload)

    return res?.data?.data
  } catch (error) {
    return error
  }
}

export const getBankAff = async (params: any) => {
  try {
    const res = await http.get(`aff/banks`, {
      params
    })

    return res?.data
  } catch (error) {
    return error
  }
}
