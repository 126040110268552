import React from 'react'
import {Card} from 'antd'
import {KTSVG} from '../../../../_metronic/helpers'
import {Dropdown1} from '../../../../_metronic/partials'

const IntegratedInstruction = () => {
  return (
    <Card>
      <div className='card-header border-0 pt-5 d-flex justify-content-between'>
        <h3 className='card-title align-items-start flex-column d-flex'>
          <span className='card-label fw-bold fs-3 mb-1'>HƯỚNG DẪN TÍCH HỢP</span>
          <span className='text-muted fw-semibold fs-7'>Nhúng mã code dưới đây vào header website</span>
        </h3>
        <div className='card-toolbar cursor-pointer'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary fs-4'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          {/* <Dropdown1 /> */}
          {/* end::Menu */}
        </div>
      </div>
      <div className='card-body pt-5'>
        {' '}
        <textarea
          className='form-control form-control-solid mb-8'
          rows={5}
          placeholder='Integrated instruction code'
        >
          {`
          <script src="${process.env.REACT_APP_AFF_LINK}plugins/integrate.js"></script>
          `}
        </textarea>
      </div>
    </Card>
  )
}

export default IntegratedInstruction
