/* eslint-disable no-sparse-arrays */
import {lazy} from 'react'

import {DashboardWrapper} from '../../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../../pages/MenuTestPage'
import {Horizontal} from '../../modules/wizards/components/Horizontal'
import {Vertical} from '../../modules/wizards/components/Vertical'
import PaymentMethod from '../../modules/payments/PaymentMethod'
import {Overview} from '../../modules/profile/components/Overview'
import {Projects} from '../../modules/profile/components/Projects'
import {Campaigns} from '../../modules/profile/components/Campaigns'
import {Documents} from '../../modules/profile/components/Documents'
import {Connections} from '../../modules/profile/components/Connections'
import {Lists} from '../../modules/widgets/components/Lists'
import GroupAffiliate from '../../pages/GroupAffiliate/GroupAffiliate'
import ListAffiliate from '../../../app/pages/GroupAffiliate/ListAffiliate'
import {Tables} from '../../../app/modules/widgets/components/Tables'
import Order from '../../../app/modules/order/Order'
import SubmitCourse from '../../../app/modules/order/SubmitCourse'
import {Mixed} from '../../../app/modules/widgets/components/Mixed'
import Link from '../../../app/modules/profile/components/Link'
import IntegratedInstruction from '../../../app/modules/profile/components/IntegratedInstruction'
import Customer from '../../../app/modules/profile/components/Customer'
import Partner from '../../../app/modules/profile/components/Partner'
import {PartnerDetails} from '../../../app/modules/profile/components/PartnerDetails'
import {Settings} from '../../../app/modules/accounts/components/settings/Settings'
const BuilderPageWrapper = lazy(() => import('../../pages/layout-builder/BuilderPageWrapper'))
const ProfilePage = lazy(() => import('../../modules/profile/ProfilePage'))
const WizardsPage = lazy(() => import('../../modules/wizards/WizardsPage'))
const AccountPage = lazy(() => import('../../modules/accounts/AccountPage'))
const WidgetsPage = lazy(() => import('../../modules/widgets/WidgetsPage'))
const ChatPage = lazy(() => import('../../modules/apps/chat/ChatPage'))
const Commission = lazy(() => import('../../modules/order/Commission'))
const ListCommission = lazy(() => import('../../modules/commission/ListCommission'))
// const PaymentCommission = lazy(() => import('../../modules/commission/PaymentCommission'))
const HistoryCommission = lazy(() => import('../../modules/commission/HistoryCommission'))
const TopRevenue = lazy(() => import('../../modules/affiliate/Top'))

const PrivateRouting = [
  {
    path: '/dashboard',
    Component: DashboardWrapper,
  },
  {
    path: '/builder',
    Component: BuilderPageWrapper,
  },
  {
    path: '/crafted/pages/profile',
    Component: ProfilePage,
  },

  {
    path: '/crafted/pages/wizards',
    Component: WizardsPage,
  },

  {
    path: '/crafted/widgets',
    Component: WidgetsPage,
  },

  {
    path: '/crafted/account',
    Component: AccountPage,
  },

  {
    path: '/apps/chat',
    Component: ChatPage,
  },

  {
    path: '/menu-test',
    Component: MenuTestPage,
  },
  {
    path: '/affiliate/top',
    Component: TopRevenue,
  },
  {
    path: '/affiliate/group',
    Component: GroupAffiliate,
  },

  {
    path: '/affiliate/group/:group_id',
    Component: ListAffiliate,
  },

  {
    path: '/crafted/pages/wizards/horizontal',
    Component: Horizontal,
  },
  {
    path: '/crafted/pages/wizards/vertical',
    Component: Vertical,
  },
  {
    path: '/crafted/pages/profile/overview',
    Component: Overview,
  },
  {
    path: '/crafted/pages/profile/projects',
    Component: Projects,
  },

  {
    path: '/crafted/pages/profile/campaigns',
    Component: Campaigns,
  },

  {
    path: '/crafted/pages/profile/documents',
    Component: Documents,
  },

  {
    path: '/crafted/pages/profile/connections',
    Component: Connections,
  },

  {
    path: '/crafted/widgets/lists',
    Component: Lists,
  },

  {
    path: '/crafted/widgets/tables',
    Component: Tables,
  },

  {
    path: '/integrate',
    Component: IntegratedInstruction,
  },

  {
    path: '/orders',
    Component: Order,
  },
  {
    path: '/submit-course',
    Component: SubmitCourse,
  },
  {
    path: '/commission',
    Component: Commission,
  },
  {
    path: '/crafted/widgets/mixed',
    Component: Mixed,
  },
  {
    path: '/link',
    Component: Link,
  },
  {
    path: '/affiliate/system',
    Component: Partner,
  },
  ,
  {
    path: '/profile/payment-methods',
    Component: PaymentMethod,
  },
  // {
  //   path: '/crafted/pages/profile/partner-details',
  //   Component: PartnerDetails,
  // },
  {
    path: '/profile/partner-details/:id',
    Component: PartnerDetails,
  },
  {
    path: '/profile/customer',
    Component: Customer,
  },
  {
    path: '/crafted/account/settings',
    Component: Settings,
  },

  {
    path: '/commission/list-commission',
    Component: ListCommission,
  },
  // {
  //   path: '/commission/payment-commission',
  //   Component: PaymentCommission,
  // },
  {
    path: '/commission/history-commission',
    Component: HistoryCommission,
  },
]

export default PrivateRouting
