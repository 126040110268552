import {AuthPage} from '../../modules/auth'
import {Registration} from '../../modules/auth/components/Registration'
import {ForgotPassword} from '../../modules/auth/components/ForgotPassword'

const PublicRouting = [
  {
    path: '/auth/login',
    Component: AuthPage,
  },
  {
    path: '/auth/signup/:code',
    Component: Registration,
  },
  {
    path: '/auth/signup',
    Component: Registration,
  },
  {
    path: '/auth/forgot-password',
    Component: ForgotPassword,
  },
]

export default PublicRouting
