/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useState, useEffect} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {Modal, Form, Select, Tooltip, Input, Avatar, message} from 'antd'
import ModalCUAff from './ModalCUAff'
import {ExclamationCircleFilled} from '@ant-design/icons'
import {getGroupAff, deleteGroupAff} from '../../../setup/redux/service/groupAffService'
import {useLocation} from 'react-router-dom'

type Props = {}

const ListAffiliate: React.FC<Props> = () => {
  let location = useLocation()
  const intl = useIntl()
  const form = useRef<any>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [recordModal, setRecordModal] = useState<any>({})
  const [dataTable, setDataTable] = useState<any>([])

  console.log()

  useEffect(() => {
    ;(async () => {
      getData()
    })()
  }, [])

  const getData = async () => {
    // const dataGr = await getGroup('')
    // let formatSelect = dataGr?.map((value: {id: number; name: string}) => {
    //   return {
    //     value: value?.id,
    //     label: value?.name,
    //   }
    // })
    // setListGroup(formatSelect)

    const dataAff = await getGroupAff(location.pathname.split('/')[3])
    setDataTable(dataAff?.rows)
  }

  const showModal = (record: any) => {
    if (record) {
      setRecordModal(record)
    } else {
      setRecordModal({})
    }
    setIsModalOpen(true)
  }

  console.log(dataTable)

  const handleSearch = () => {
    console.log('searchhhhhh')
  }

  const showPropsConfirm = (aff_id: any) => {
    let messageSuccess = 'Xóa thành công'

    Modal.confirm({
      title: 'Bạn muốn xóa Affiliate này?',
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        // console.log('OK', id)
        await deleteGroupAff(aff_id)
        getData()
        message.success(messageSuccess)
      },
      // onCancel() {
      //   console.log('Cancel')
      // },
    })
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      {/* <Form ref={form}>
        <div className='card-body border-0 pt-5 ' style={{display: 'flex', alignItems: 'flex-end'}}>
          <div className='px-4'>
            <label className='form-label fs-6 fw-bolder text-dark'>Tên affiliate </label>
            <Form.Item name='code'>
              <Input placeholder='Tên affiliate  ...' size='large' />
            </Form.Item>
          </div>

          <div className='px-4'>
            <label className='form-label fs-6 fw-bolder text-dark' style={{display: 'block'}}>
              Nhóm affiliate: {'  '}
            </label>
            <Form.Item name={'status'}>
              <Select
                style={{
                  width: '200px',
                }}
                size='large'
                placeholder='Chọn nhóm ...'
                options={listGroup}
              />
            </Form.Item>
          </div>

          <div className='ms-5'>
            <div className='form-label fs-6 fw-bolder text-dark mb-4'>&nbsp;</div>
            <a
              href='#'
              className='btn btn-sm btn-primary'
              onClick={handleSearch}
              style={{marginBottom: '24px'}}
            >
              Tìm kiếm
            </a>
          </div>
          <div className='ms-5'>
            <div className='form-label fs-6 fw-bolder text-dark mb-4'>&nbsp;</div>
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              onClick={async () => {
                // await getSearchOrders('')
                // await form?.current?.setFieldsValue({
                //   code: undefined,
                //   package: undefined,
                //   status: undefined,
                //   time: undefined,
                // })
              }}
              style={{marginBottom: '24px'}}
            >
              Bỏ lọc
            </a>
          </div>
        </div>
      </Form> */}

      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex'>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Back to Group'
          >
            <i
              className='bi bi-arrow-left-circle-fill cursor-pointer'
              style={{position: 'relative', bottom: 2, marginRight: 15}}
              onClick={() => window.history.back()}
            ></i>
          </div>
          <span className='card-label fw-bolder fs-3 mb-1'>
            Danh sách Affiliate trong nhóm
          </span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a affiliate'
        >
          <a href='#' onClick={showModal} className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            {intl?.formatMessage({id: 'BTN.ADD'})}
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-50px text-center'>STT</th>
                <th className='min-w-150px'>Affiliate</th>
                <th className='min-w-100px text-center'>Nhóm affiliate</th>
                <th className='min-w-100px text-end'>Thao tác</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {dataTable.length > 0 ? (
                dataTable.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className='text-center'>
                      <span className='text-dark fw-bolder fs-6'>{index + 1}</span>
                    </td>
                    <td>
                      <span className='text-dark fw-bolder text-hover-primary fs-6'>
                        {item?.name}
                      </span>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          {item?.aff?.avatar ? (
                            <img src={toAbsoluteUrl(item?.aff?.avatar)} alt='' />
                          ) : (
                            <Avatar
                              style={{
                                backgroundColor: '#cbc5c1',
                              }}
                            >
                              A
                            </Avatar>
                          )}
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {item?.aff?.name}
                          </a>
                          <span className='text-muted fw-bold text-muted d-block fs-7'>
                            {item?.aff?.email}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className='min-w-100px text-center '>
                      <span className='text-dark fw-bolder text-hover-primary fs-6'>
                        {item?.group?.name}
                      </span>
                    </td>

                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        {/* <Tooltip title='Sửa' placement='bottom'>
                          {' '}
                          <button
                            onClick={() => showModal(item)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </button>
                        </Tooltip> */}
                        <Tooltip title='Xóa' placement='bottom'>
                          {' '}
                          <button
                            onClick={() => showPropsConfirm(item?.aff_id)}
                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </button>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  {/* <tr> */}
                  <div>
                    <span className='text-muted me-2 fs-7 fw-bold'>Không có dữ liệu</span>
                  </div>
                  {/* </tr> */}
                </>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <ModalCUAff
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        recordModal={recordModal}
        setRecordModal={setRecordModal}
        refresh={() => getData()}
      />
    </div>
  )
}

export default ListAffiliate
