import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  data: {},
}

export const getUserByTokenReducer = createSlice({
  name: 'get-user-by-token',
  initialState,
  reducers: {
    setUserByToken(state, action) {
      state.data = action.payload
    },
  },
})

export const {setUserByToken} = getUserByTokenReducer.actions

export default getUserByTokenReducer.reducer
