import {message} from 'antd'
import {http} from '../../axios/SetupAxios'

export const loginService = async (values: {email: string; password: string}) => {
  try {
    const {data} = await http.post('/auth/login-aff', values)

    return data
  } catch (error: any) {
    message.error('Đăng nhập thất bại !')
    throw new Error(error)
  }
}

export const getUserByToken = async (token: string) => {
  try {
    const {data}: any = await http.get(`/profile/getUserByToken?token=${token}`)
    return data.data || {}
  } catch (error) {
    message.error(error)
    return error
  }
}

export const getUserAffByToken = async (token: string) => {
  try {
    const {data} = await http.get(`/profile/getUserAff?token=${token}`)
    return data.data || {}
  } catch (error) {
    message.error(error)
    return error
  }
}

export const register = async (data: any) => {
  try {
    const dataValue = await http.post(`/auth/register-aff`, data)

    return dataValue
  } catch (error) {
    return error
  }
}
