import {call, put, takeEvery} from 'redux-saga/effects'
import {createAction} from '@reduxjs/toolkit'
import {setUserByToken} from '../reducer/getUserByToken'

import constants from '../actions/constants'
import {getUserByToken} from '../service/loginService'

export const actionGetUserByToken = createAction(constants.GET_USER_BY_TOKEN)

export function* getUserByTokenSaga(action: any): any {
  try {
    let data: any = yield call(() => getUserByToken(action?.payload))

    yield put(setUserByToken(data))
  } catch (error) {
    return Promise.reject(error)
  }
}

export function* watchUserByToken() {
  yield takeEvery(actionGetUserByToken, getUserByTokenSaga)
}
