import React, {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import DashboardAdmin from './DashboardAdmin'
import DashboardCustomer from './DashboardCustomer'
import {useSelector} from 'react-redux'
const DashboardWrapper: FC = () => {
  const intl = useIntl()

  const user = useSelector((state: any) => state?.userByToken?.data)

  console.log(user)
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      {user?.aff_id ? <DashboardCustomer /> : <DashboardAdmin />}
    </>
  )
}

export {DashboardWrapper}
