/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from 'src/_metronic/helpers'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {searchOrder} from 'src/setup/redux/service/ordersService'
const loginSchema = Yup.object().shape({
  code: Yup.string().max(50, 'Maximum 50 symbols').required('Code is required'),
})

const initialValues = {
  code: '',
}

const Customer = () => {
  const [loading, setLoading] = useState(false)
  const [dataTable, setDataTable] = useState([])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      console.log(values)
      getSearchOrders(values)
    },
  })

  const getSearchOrders = async (code: any) => {
    const params = `limit=${10}&page=${1}&code=${code}`
    const data = await searchOrder(params)
    setDataTable(data?.data?.rows)
  }

  useEffect(() => {
    getSearchOrders('')
  }, [])

  return (
    <div>
      <div className={`card mb-5 mb-xl-8 `}>
        {/* begin::Header */}
        <div className='card-body border-0 pt-5 mb-5' style={{display: 'flex'}}>
          <div>
            <label className='form-label fs-6 fw-bolder text-dark'>
              Tìm kiếm theo tên khách hàng
            </label>
            <input
              placeholder='Tên khách hàng .....'
              {...formik.getFieldProps('code')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.code && formik.errors.code},
                {
                  'is-valid': formik.touched.code && !formik.errors.code,
                }
              )}
              type='code'
              name='code'
              autoComplete='off'
            />
            {formik.touched.code && formik.errors.code && (
              <div className='fv-plugins-message-container position-absolute text-danger top-10'>
                <span role='alert'>{formik.errors.code}</span>
              </div>
            )}
          </div>
          <div className='ms-5'>
            <div className='form-label fs-6 fw-bolder text-dark mb-4'>&nbsp;</div>
            <a href='#' className='btn btn-sm btn-primary' onClick={formik.submitForm}>
              Tìm kiếm
            </a>
          </div>
        </div>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>New Arrivals</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Over 500 new products</span>
          </h3>
          <div className='card-toolbar'>
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_app'
              id='kt_toolbar_primary_button'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              New Member
            </a>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted bg-light'>
                  <th className='ps-4 min-w-325px rounded-start'>Tên khách hàng</th>
                  <th className='min-w-125px'>Mã sản phẩm</th>
                  {/* <th className='min-w-125px'>Nhà phân phối</th> */}

                  <th className='min-w-200px'>Giá</th>
                  <th className='min-w-200px'>Giảm giá</th>
                  <th className='min-w-125px'>Gói</th>

                  <th className='min-w-200px text-end rounded-end'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {dataTable?.map((value: any, index: number) => {
                  return (
                    <tr>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a
                              href='#'
                              className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
                            >
                              {value?.customer.name}
                            </a>
                            <span className='text-muted fw-bold text-muted d-block fs-7'>
                              {value?.customer.email}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                        >
                          {value?.code}
                        </a>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          Số lượng: {value.quantity}
                        </span>
                      </td>
                      {/* <td>
                        <a
                          href='#'
                          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                        >
                          {value?.distributor?.name}
                        </a>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {value?.distributor?.email}
                        </span>
                      </td> */}
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                        >
                          {value?.origin_price.toLocaleString('it-IT', {
                            style: 'currency',
                            currency: 'VND',
                          })}
                        </a>
                        {/* <span className='text-muted fw-bold text-muted d-block fs-7'>
                          Insurance
                        </span> */}
                      </td>

                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                        >
                          {value?.discount.toLocaleString('it-IT', {
                            style: 'currency',
                            currency: 'VND',
                          })}
                        </a>
                        {/* <span className='text-muted fw-bold text-muted d-block fs-7'>
                          Insurance
                        </span> */}
                      </td>

                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                        >
                          {value?.packageData?.name}
                        </a>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          Giá gói:{' '}
                          {value?.packageData?.price.toLocaleString('it-IT', {
                            style: 'currency',
                            currency: 'VND',
                          })}
                        </span>
                      </td>

                      <td className='text-end'>
                        <a
                          href='#'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen019.svg'
                            className='svg-icon-3'
                          />
                        </a>
                        <a
                          href='#'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </a>
                        <a
                          href='#'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </div>
  )
}

export default Customer
