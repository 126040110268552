/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {QRCode, Card, Select, Input, Button, message} from 'antd'
import {AiOutlineCheck} from 'react-icons/ai'
import {getPackage} from '../../../../setup/redux/service/ordersService'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {getRefCode, changeRefCode} from '../../../../setup/redux/service/paymentService'

const Link = () => {
  const [linkProduct, setLinkProduct] = useState<string>('')
  const [linkCourse, setLinkCourse] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [isCheckChange, setIsCheckChange] = useState<boolean>(false)
  const [stateCopy, setStateCopy] = useState<{
    isIntro: boolean
    isProducts: boolean
    isCourse: boolean
  }>({
    isIntro: false,
    isProducts: false,
    isCourse: false
  })
  const intl = useIntl()

  const [dataPackage, setDataPackage] = useState<[]>([])
  const [dataCourse, setDataCourse] = useState<[]>([])

  useEffect(() => {
    ;(async () => {
      const refCode = await getRefCode('')
      setCode(refCode?.code)

      const data = await getPackage('')
      if (data?.code !== 200) {
        return message.error('Gọi gói thất bại !')
      }
      const format = data?.data?.map((value: {id: number; name: string}) => {
        return {
          value: value?.id,
          label: value?.name,
          ...value,
        }
      })
      setDataPackage(format)

      const data2 = await getPackage({course: 1})
      if (data2?.code !== 200) {
        return message.error('Gọi gói thất bại !')
      }
      const format2 = data2?.data?.map((value: {id: number; name: string}) => {
        return {
          value: value?.id,
          label: value?.name,
          ...value,
        }
      })
      setDataCourse(format2)
    })()
  }, [])

  const handleChange = (value: any) => {
    dataPackage?.find((item: any) => {
      if (item?.value === value) {
        setLinkProduct(`${item?.link}?code=${code}`)
      }
    })
  }

  const handleChangeCourse = (value: any) => {
    dataCourse?.find((item: any) => {
      if (item?.value === value) {
        console.log('xxxxx')
        setLinkCourse(`${item?.link}?code=${code}`)
      }
    })
  }

  const handleChangeCode = async () => {
    await changeRefCode({code: code})
    setIsCheckChange(!isCheckChange)
  }

  return (
    <Card style={{height: '100%'}}>
      <div className='card mb-2 mb-xl-2' style={{border: '1px solid #eff2f5'}}>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Mã của bạn</h3>
          </div>
        </div>

        <div id='kt_account_signin_method' className='collapse show'>
          <div className='card-body border-top px-9 py-5'>
            <div className='d-flex flex-wrap align-items-center justify-content-between'>
              <div id='kt_signin_password'>
                <div className='fs-6 fw-bolder mb-1'>Mã tiếp thị</div>

                {!isCheckChange ? (
                  <div className='fw-bold text-gray-600 px-5 py-2 '>{code} &nbsp;</div>
                ) : (
                  <input
                    type='text'
                    className='input_custom_a form-control form-control-solid'
                    defaultValue={code}
                    onChange={(e) => setCode(e.target.value.replaceAll(' ', ''))}
                  />
                )}
              </div>

              <div id='kt_signin_password_button'>
                {!isCheckChange ? (
                  <button
                    className='btn btn-light btn-active-light-primary'
                    onClick={() => setIsCheckChange(!isCheckChange)}
                  >
                    Thay dổi
                  </button>
                ) : (
                  <button className='btn btn-primary' onClick={handleChangeCode}>
                    {' '}
                    Lưu lại
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-2 mb-xl-2' style={{border: '1px solid #eff2f5'}}>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Link khoá học</h3>
          </div>
        </div>

        <div id='kt_account_signin_method' className='collapse show'>
          <div className='card-body border-top px-9 py-6'>
            <div className='d-flex flex-wrap align-items-center justify-content-between'>
              <div id='kt_signin_email'>
                <div className='fs-6 fw-bolder mb-1'>Chọn khoá học</div>
                {/* <div className='fw-bold text-gray-600'>AIVA Premium - 12 Tháng</div> */}
              </div>

              <div id='kt_signin_email_button'>
                <div className='custom_select'>
                  <Select
                    options={dataCourse}
                    placeholder='Chọn gói ...'
                    style={{
                      width: 220,
                    }}
                    onChange={(value: any) => handleChangeCourse(value)}
                  />
                </div>
              </div>
            </div>

            <div className='separator separator-dashed my-6'></div>

            <div className='d-flex flex-wrap align-items-center mb-6 justify-content-between'>
              <div id='kt_signin_password'>
                <div className='fs-6 fw-bolder mb-1'>Link khoá học</div>
                <div className='fw-bold text-gray-600'>{linkCourse} &nbsp;</div>
              </div>

              <div id='kt_signin_password_button'>
                <button
                  className='btn btn-light btn-active-light-primary'
                  onClick={() => {
                    navigator.clipboard.writeText(linkCourse)
                    setStateCopy({
                      isIntro: false,
                      isProducts: false,
                      isCourse: true
                    })
                  }}
                >
                  {stateCopy.isCourse ? (
                    <span className='copied'>
                      Sao chép{' '}
                      <i className='ml-2'>
                        <AiOutlineCheck />
                      </i>
                    </span>
                  ) : (
                    <span> Sao chép !</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='card mb-2 mb-xl-2' style={{border: '1px solid #eff2f5'}}>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Link sản phẩm</h3>
          </div>
        </div>

        <div id='kt_account_signin_method' className='collapse show'>
          <div className='card-body border-top px-9 py-6'>
            <div className='d-flex flex-wrap align-items-center justify-content-between'>
              <div id='kt_signin_email'>
                <div className='fs-6 fw-bolder mb-1'>Chọn gói</div>
                {/* <div className='fw-bold text-gray-600'>AIVA Premium - 12 Tháng</div> */}
              </div>

              <div id='kt_signin_email_button'>
                <div className='custom_select'>
                  <Select
                    options={dataPackage}
                    placeholder='Chọn gói ...'
                    style={{
                      width: 220,
                    }}
                    onChange={(value: any) => handleChange(value)}
                  />
                </div>
              </div>
            </div>

            <div className='separator separator-dashed my-6'></div>

            <div className='d-flex flex-wrap align-items-center mb-6 justify-content-between'>
              <div id='kt_signin_password'>
                <div className='fs-6 fw-bolder mb-1'>Link sản phẩm</div>
                <div className='fw-bold text-gray-600'>{linkProduct} &nbsp;</div>
              </div>

              <div id='kt_signin_password_button'>
                <button
                  className='btn btn-light btn-active-light-primary'
                  onClick={() => {
                    navigator.clipboard.writeText(linkProduct)
                    setStateCopy({
                      isIntro: true,
                      isProducts: false,
                      isCourse: false
                    })
                  }}
                >
                  {stateCopy.isIntro ? (
                    <span className='copied'>
                      Sao chép{' '}
                      <i className='ml-2'>
                        <AiOutlineCheck />
                      </i>
                    </span>
                  ) : (
                    <span> Sao chép !</span>
                  )}
                </button>
              </div>
            </div>
            {/* <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6'>
              <KTSVG
                path='/media/icons/duotune/general/gen005.svg'
                className='svg-icon-2tx svg-icon-primary me-4 d-flex align-items-center'
              />
              <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                <div className='mb-3 mb-md-0 fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>Tiếp thị liên kết</h4>
                  <div className='fs-6 text-gray-600 pe-7'>Hoa hồng nhận được: 10%</div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* <div className='card mb-2 mb-xl-2' style={{border: '1px solid #eff2f5'}}>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Link đăng ký</h3>
          </div>
        </div>

        <div id='kt_account_signin_method' className='collapse show'>
          <div className='card-body border-top px-9 py-6'>
            <div className='d-flex flex-wrap align-items-center mb-6 justify-content-between'>
              <div id='kt_signin_password'>
                <div className='fs-6 fw-bolder mb-1'>Link đăng ký</div>
                <div className='fw-bold text-gray-600'>{`${process.env.REACT_APP_AFF_LINK}auth/signup?code=${code}`}</div>
              </div>

              <div id='kt_signin_password_button'>
                <button
                  className='btn btn-light btn-active-light-primary'
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${process.env.REACT_APP_AFF_LINK}auth/signup?code=${code}`
                    )
                    setStateCopy({
                      isIntro: false,
                      isProducts: true,
                      isCourse: false
                    })
                  }}
                >
                  {stateCopy.isProducts ? (
                    <span className='copied'>
                      Sao chép{' '}
                      <i className='ml-2'>
                        <AiOutlineCheck />
                      </i>
                    </span>
                  ) : (
                    <span> Sao chép !</span>
                  )}
                </button>
              </div>
            </div>

            <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6'>
              <KTSVG
                path='/media/icons/duotune/general/gen002.svg'
                className='svg-icon-2tx svg-icon-primary me-4 d-flex align-items-center'
              />
              <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                <div className='mb-3 mb-md-0 fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>Chương trình liên kết</h4>
                  <div className='fs-6 text-gray-600 pe-7'>
                    Mời bạn bè và người khác nhận hoa hồng từ đường dẫn đăng ký.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Card>
  )
}

export default Link
