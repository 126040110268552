/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Card, Input, Tooltip, Skeleton, Modal, message} from 'antd'
import ModalAddPayment from './ModalCUPayment'
import {MdVerified, MdOutlineMoreVert} from 'react-icons/md'
import {BiEdit} from 'react-icons/bi'
import {AiTwotoneDelete} from 'react-icons/ai'
import {ExclamationCircleFilled} from '@ant-design/icons'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useSelector} from 'react-redux'
import {http} from 'src/setup/axios/SetupAxios'

const PaymentMethod = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [listBank, setListBank] = useState<{}[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [valueUpdate, setValueUpdate] = useState<any>([])

  const user: any = useSelector((state: any) => state?.userByToken?.data)

  const handleUpdate = (id: number) => {
    setValueUpdate(listBank?.filter((data: any) => data.id === id))
    setIsModalOpen(true)
  }

  useEffect(() => {
    ;(async () => {
      await getListBank()
    })()
  }, [])

  const getListBank = async () => {
    setIsLoading(true)
    try {
      const res = await http.get('/aff/banks')
      setListBank(res?.data?.data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const Actions = ({record}: any) => {
    return (
      <>
        <div className='action_form'>
          <Card hoverable>
            <div
              className='text-dark fw-bolder text-hover-primary fs-6'
              onClick={() => handleUpdate(record?.id)}
            >
              <i>
                <BiEdit />
              </i>{' '}
              Cập nhật
            </div>
            <div
              className='text-hover-danger text-dark fw-bolder fs-6'
              onClick={() => showPropsConfirm(record?.id)}
            >
              <i>
                <AiTwotoneDelete />
              </i>{' '}
              Xóa
            </div>
          </Card>{' '}
        </div>
      </>
    )
  }

  const showPropsConfirm = (id: number) => {
    Modal.confirm({
      title: 'Bạn muốn xóa liên kết với thẻ này?',
      icon: <ExclamationCircleFilled />,
      centered: true,
      okText: 'Đồng ý',
      okType: 'danger',
      cancelText: 'Không',

      async onOk() {
        try {
          const res = await http.post('/aff/remove-bank', {id: id})
          if (res?.data?.code !== 200) {
            return message.error('Xoá không thành công, có lỗi xảy ra !')
          }
          message.success('Đã xoá !')
          return getListBank()
        } catch (error) {
          return message.error('Xoá không thành công, có lỗi xảy ra !')
        }
      },
      // onCancel() {
      //   console.log('Cancel')
      // },
    })
  }

  return (
    <Card
      title={
        <>
          <div className='d-flex justify-content-between align-items-center'>
            <p className='text-dark fw-bolder text-hover-primary fs-6'>Danh sách thẻ</p>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a payment'
            >
              <a
                href='#'
                onClick={() => {
                  setIsModalOpen(true)
                  setValueUpdate([])
                }}
                className='btn btn-sm btn-light-primary'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Thêm phương thức
              </a>
            </div>
          </div>
        </>
      }
      style={{
        height: 'calc(100vh - 120px)',
      }}
    >
      {isModalOpen && (
        <ModalAddPayment
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          user={user}
          getListBanks={getListBank}
          valueUpdate={valueUpdate}
        />
      )}

      <div className='form_container row mx-10'>
        <div className='list_card'>
          <Skeleton loading={isLoading} active>
            {listBank.map((item: any, index: number) => {
              return (
                <div className='list_card_items col-sm-12' key={index}>
                  <div className='list_card_item d-flex align-items-center justify-content-between px-5 py-5'>
                    <div className='d-flex align-items-center'>
                      <div className='list_card_item_img'>
                        <img src={item?.bank?.image} alt='image' />
                      </div>
                      <div className='list_card_item_details '>
                        <div className='d-flex'>
                          <p className='text-dark fw-bolder text-hover-primary fs-6'>
                            {item?.bank?.name}
                          </p>
                          {item?.is_default === 1 && <span className='px-2'>Mặc định</span>}
                          <div className='d-flex align-items-center mx-2'>
                            <i
                              style={{
                                marginRight: '5px',
                              }}
                            >
                              <MdVerified />
                            </i>
                            <span>Đã xác thực</span>
                          </div>
                        </div>
                        <div>
                          <p className='text-dark fw-bolder text-hover-primary fs-6'>
                            {item?.bank_owner} - {item?.bank_account}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='list_card_item_item_control'>
                      <Tooltip placement='bottom' title={<Actions record={item} />} color='white'>
                        <i className='fs-1'>
                          <MdOutlineMoreVert />
                        </i>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              )
            })}
          </Skeleton>
        </div>
      </div>
    </Card>
  )
}

export default PaymentMethod
