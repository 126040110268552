/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Form, Input, Switch, InputNumber, Button, Modal, message} from 'antd'
import {useIntl} from 'react-intl'
import {createUpdateGroup} from '../../../setup/redux/service/groupAffService'

export interface Props {
  isModalOpen: boolean
  setIsModalOpen: any
  recordModal: any
  setRecordModal: any
  getData?: any
  isActive: boolean
  setIsActive: any
}

const ModalCUGrrAff = ({
  isModalOpen,
  setIsModalOpen,
  recordModal,
  setRecordModal,
  getData,
  isActive,
  setIsActive,
}: Props) => {
  const intl = useIntl()
  const formRef = React.createRef<any>()
  // const [isActive, setIsActive] = useState<boolean>(false)

  const handleOk = async () => {
    // await formRef.current.validateFields()
    let {name, commission, commission_child} = formRef.current.getFieldsValue()
    let dataPush = {
      name: name || '',
      commission: commission,
      commission_child: commission_child,
      active: isActive === true ? 1 : 0,
    }
    let messageSuccess = 'Thêm mới thành công'

    if (recordModal && recordModal.id) {
      let dataEdit = {
        ...dataPush,
        id: recordModal?.id,
      }
      messageSuccess = 'Cập nhật thành công'
      await createUpdateGroup(dataEdit)
    } else {
      await createUpdateGroup(dataPush)
    }
    setRecordModal({del: 1})
    message.success(messageSuccess)
    setIsModalOpen(false)
    getData()
  }

  const handleCancel = () => {
    setRecordModal({del: 1})
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (recordModal?.del === 0) {
      const obj_form = {
        name: recordModal?.name,
        commission: recordModal?.commission,
        commission_child: recordModal?.commission_child,
      }

      formRef?.current?.setFieldsValue(obj_form)
    }
  }, [recordModal])

  const onChangeActive = (checked: boolean) => {
    setIsActive(checked)
  }

  return (
    <Modal
      title={
        recordModal?.del === 0
          ? intl?.formatMessage({id: 'BTN.UPDATE'})
          : intl?.formatMessage({id: 'BTN.ADD'})
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {intl?.formatMessage({id: 'BTN.CANCEL'})}
        </Button>,
        <Button key='submit' type='primary' onClick={handleOk}>
          {recordModal?.del === 0
            ? intl?.formatMessage({id: 'BTN.UPDATE'})
            : intl?.formatMessage({id: 'BTN.ADD'})}
        </Button>,
        // <button className='ms-5'>
        //   <div className='form-label fs-6 fw-bolder text-dark mb-4'>&nbsp;</div>
        //   <a
        //     href='#'
        //     className='btn btn-sm btn-light-primary'
        //     onClick={handleCancel}
        //     style={{marginBottom: '24px'}}
        //   >
        //     {intl?.formatMessage({id: 'BTN.CANCEL'})}
        //   </a>
        // </button>,
        // <button className='ms-5'>
        //   <div className='form-label fs-6 fw-bolder text-dark mb-4'>&nbsp;</div>
        //   <a
        //     href='#'
        //     className='btn btn-sm btn-primary'
        //     onClick={handleOk}
        //     style={{marginBottom: '24px'}}
        //   >
        //     {recordModal?.del === 0
        //       ? intl?.formatMessage({id: 'BTN.UPDATE'})
        //       : intl?.formatMessage({id: 'BTN.ADD'})}
        //   </a>
        // </button>,
      ]}
    >
      <Form ref={formRef} style={{marginTop: '20px'}}>
        <div className='form_container row'>
          <div className='col-sm-6'>
            <div className='customLabel'>
              Tên nhóm
              <span className='customRequired'>*</span>
            </div>
            <Form.Item name='name'>
              <Input placeholder='Nhập tên nhóm...' />
            </Form.Item>
          </div>
          <div className='col-sm-6'>
            <div className='customLabel'>
              Active
              <span className='customRequired'>*</span>
            </div>
            <Form.Item name='active'>
              <div>
                <Switch checked={isActive} onChange={onChangeActive} />
                <span style={{marginLeft: '10px', fontWeight: '600'}}>
                  {isActive === true ? 'On' : 'Off'}
                </span>
              </div>
            </Form.Item>
          </div>
          <div className='col-sm-6'>
            <div className='customLabel'>
              Hoa hồng trực tiếp
              <span className='customRequired'>*</span>
            </div>
            <Form.Item name='commission'>
              <InputNumber min={0} max={100} defaultValue={0} formatter={(value) => `${value}%`} />
            </Form.Item>
          </div>
          <div className='col-sm-6'>
            <div className='customLabel'>
              Hoa hồng gián tiếp
              <span className='customRequired'>*</span>
            </div>
            <Form.Item name='commission_child'>
              <InputNumber min={0} max={100} defaultValue={0} formatter={(value) => `${value}%`} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalCUGrrAff
