/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {KTSVG} from 'src/_metronic/helpers'
import {useIntl} from 'react-intl'

import {formatMoney, formatTime} from '../../libs/money'
import {searchOrder, getPackage} from 'src/setup/redux/service/ordersService'
import {ExclamationCircleFilled, CheckCircleOutlined} from '@ant-design/icons'
import {DatePicker, Select, message, Form, Tooltip, Modal, Input, Skeleton} from 'antd'
import {useSelector} from 'react-redux'
import {http} from 'src/setup/axios/SetupAxios'
const {RangePicker} = DatePicker
const {TextArea} = Input

const SubmitCourse = () => {
  const ref_success = useRef<any>()
  const ref_error = useRef<any>()

  const intl = useIntl()
  const form = useRef<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [dataTable, setDataTable] = useState<[]>([])
  const [dataPackage, setDataPackage] = useState<[]>([])
  const user: any = useSelector((state: any) => state?.userByToken?.data)
  const isAdmin = user && user.type === 1 && !user.aff_id

  const getSearchOrders = async (param: any) => {
    setLoading(true)
    const data = await searchOrder({
        ...(param || {}),
        course: 1
    })
    setDataTable(data?.data?.rows)
    const datas = await getPackage({course: 1})
    if (data?.code !== 200) {
      return message.error('Gọi gói thất bại !')
    }
    const format = await datas?.data?.map((value: {id: number; name: string}) => {
      return {
        value: value?.id,
        label: value?.name,
      }
    })
    setDataPackage(format)
    setLoading(false)
  }
  useEffect(() => {
    ;(async () => {
      getSearchOrders('')
    })()
  }, [])

  const handleSubmit = async () => {
    const data = await form?.current?.getFieldsValue()
    const time = {
      startTime: data?.time?.length ? data?.time[0]['$d'].toISOString() : '',
      endTime: data?.time?.length ? data?.time[1]['$d'].toISOString() : '',
    }
    let params = `limit=${10}&page=${1}&code=${data?.code || ''}&start=${time?.startTime}&end=${
      time?.endTime
    }&status=${data?.status || ''}&package_id=${data?.package || ''}`

    await getSearchOrders({
      limit: 10,
      page: 1,
      code: data?.code || '',
      start: time?.startTime || '',
      end: time?.endTime || '',
      status: data?.status,
      package_id: data?.package,
    })
  }

  const handleOrders = async (type: any, id: any, instance: any) => {
    const note =
      type === 1
        ? ref_success?.current?.resizableTextArea?.textArea?.value
        : type === 2
        ? ref_error?.current?.resizableTextArea?.textArea?.value
        : null
    try {
      const res = await http.post('/aff/confirm-order', {
        id: id,
        status: type,
        note: note,
      })

      if (res?.data?.code !== 200) {
        return message.error('Thất bại !')
      }
      await instance.destroy()
      await getSearchOrders('')
    } catch (error) {
      return message.error('Thất bại !')
    }
  }

  const showPropsConfirmExit = (value: any) => {
    const instance = Modal.confirm({
      className: 'modal_confirm_custom',
      title: (
        <>
          <div className='d-flex align-items-center'>
            <i className='fs-6 px-2' style={{color: '#f1416c'}}>
              <ExclamationCircleFilled />
            </i>
            <p className='text-dark fw-bolder text-hover-primary fs-4 mx-2'>
              Bạn muốn huỷ bỏ đơn hàng này !
            </p>
          </div>
          <div className='separator separator-dashed my-6'></div>
        </>
      ),
      icon: null,
      content: (
        <>
          <div>
            <div className='px-2'>
              <div>
                <div className='d-flex justify-content-between align-items-center my-5'>
                  <p className='text-muted mt-1 fw-bold fs-7'>Tên khách hàng:</p>
                  <span className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                    {value?.customer?.name}
                  </span>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <p className='text-muted mt-1 fw-bold fs-7'>Gói:</p>
                  <span className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                    {value?.packageData?.name}
                  </span>
                </div>
                <div className='d-flex justify-content-between align-items-center my-5'>
                  <p className='text-muted mt-1 fw-bold fs-7'>Tổng tiền:</p>
                  <span className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                    {formatMoney(value?.total_price)}
                  </span>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed my-6'></div>
            <p className='form-label fs-6 fw-bolder text-dark'>Nhập lý do:</p>
            <TextArea ref={ref_error} />
          </div>
        </>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,

      footer: (
        <>
          <div className='pt-8 d-flex justify-content-end'>
            <a
              key='back'
              className='btn btn-sm btn-light-primary mx-3'
              onClick={() => instance.destroy()}
            >
              {intl?.formatMessage({id: 'BTN.CANCEL'})}
            </a>

            <a
              key='submit'
              type='primary'
              className='btn btn-sm btn-primary mx-3'
              onClick={() => handleOrders(2, value.id, instance)}
            >
              {intl?.formatMessage({id: 'BTN.OK'})}
            </a>
          </div>
        </>
      ),
    })
  }
  const showPropsConfirmSuccess = (value: any) => {
    const instance = Modal.info({
      className: 'modal_confirm_custom',
      title: (
        <>
          <div className='d-flex align-items-center'>
            <i className='fs-6 px-2' style={{color: '#009ef7'}}>
              <CheckCircleOutlined />
            </i>
            <p className='text-dark fw-bolder text-hover-primary fs-4 mx-2'>
              Xác nhận khách hàng này
            </p>
          </div>
          <div className='separator separator-dashed my-6'></div>
        </>
      ),
      icon: null,
      content: (
        <>
          <div>
            <div className='px-2'>
              <div>
                <div className='d-flex justify-content-between align-items-center my-5'>
                  <p className='text-muted mt-1 fw-bold fs-7'>Tên khách hàng:</p>
                  <span className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                    {value?.customer?.name}
                  </span>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <p className='text-muted mt-1 fw-bold fs-7'>Khoá:</p>
                  <span className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                    {value?.packageData?.name}
                  </span>
                </div>
                <div className='d-flex justify-content-between align-items-center my-5'>
                  <p className='text-muted mt-1 fw-bold fs-7'>Tổng tiền:</p>
                  <span className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                    {formatMoney(value?.total_price)}
                  </span>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed my-6'></div>
            <p className='form-label fs-6 fw-bolder text-dark'>Nhập lý do:</p>
            <TextArea ref={ref_success} />
          </div>
        </>
      ),
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      centered: true,

      footer: (
        <>
          <div className='pt-8 d-flex justify-content-end'>
            <a
              key='back'
              className='btn btn-sm btn-light-primary mx-3'
              onClick={() => instance.destroy()}
            >
              {intl?.formatMessage({id: 'BTN.CANCEL'})}
            </a>

            <a
              key='submit'
              type='primary'
              className='btn btn-sm btn-primary mx-3'
              onClick={() => handleOrders(1, value.id, instance)}
            >
              {intl?.formatMessage({id: 'BTN.OK'})}
            </a>
          </div>
        </>
      ),
    })
  }

  return (
    <div>
      <div className={`card mb-5 mb-xl-8 `}>
        {/* begin::Header */}

        <Form ref={form}>
          <div
            className='card-body border-0 px-5 pb-0'
            style={{display: 'flex', alignItems: 'flex-end'}}
          >
            <div className='px-4'>
              <label className='form-label fs-6 fw-bolder text-dark' style={{display: 'block'}}>
                Thời gian : {'  '}
              </label>
              <Form.Item name={'time'}>
                <RangePicker size='middle' />
              </Form.Item>
            </div>

            <div className='px-4'>
              <label className='form-label fs-6 fw-bolder text-dark' style={{display: 'block'}}>
                Chọn khoá : {'  '}
              </label>
              <Form.Item name={'package'}>
                <Select
                  size='middle'
                  placeholder='Chọn khoá ...'
                  options={dataPackage}
                  style={{
                    width: '200px',
                  }}
                />
              </Form.Item>
            </div>

            <div className='px-4'>
              <label className='form-label fs-6 fw-bolder text-dark' style={{display: 'block'}}>
                Trạng thái : {'  '}
              </label>
              <Form.Item name={'status'}>
                <Select
                  style={{
                    width: '150px',
                  }}
                  size='middle'
                  placeholder='Chọn trạng thái ...'
                  options={[
                    {
                      value: 1,
                      label: 'Thành công',
                    },

                    {
                      value: 0,
                      label: 'Chờ xử lý',
                    },
                    {
                      value: 2,
                      label: 'Bị huỷ',
                    },
                  ]}
                />
              </Form.Item>
            </div>

            <div className='ms-5'>
              <div className='form-label fs-6 fw-bolder text-dark mb-4'>&nbsp;</div>
              <a
                href='#'
                className='btn btn-sm btn-primary'
                onClick={handleSubmit}
                style={{marginBottom: '24px'}}
              >
                Tìm kiếm
              </a>
            </div>
            <div className='ms-5'>
              <div className='form-label fs-6 fw-bolder text-dark mb-4'>&nbsp;</div>
              <a
                href='#'
                className='btn btn-sm btn-light-primary'
                onClick={async () => {
                  await getSearchOrders('')
                  await form?.current?.setFieldsValue({
                    code: undefined,
                    package: undefined,
                    status: undefined,
                    time: undefined,
                  })
                }}
                style={{marginBottom: '24px'}}
              >
                Bỏ lọc
              </a>
            </div>
          </div>
        </Form>

        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <Skeleton loading={loading} active>
                <thead>
                  <tr className='fw-bolder text-muted bg-light'>
                    <th className='ps-4 min-w-175px rounded-start'>Khách hàng</th>
                    <th className='min-w-200px'>SĐT</th>
                    <th className='min-w-200px'>Email</th>
                    {isAdmin && <th className='min-w-200px'>Đại Lý</th>}
                    {isAdmin && <th className='min-w-200px'>CTV</th>}
                    <th className='min-w-200px'>Khoá</th>
                    {/* <th className='min-w-150px'>Tổng tiền</th> */}
                    {/* <th className='min-w-150px'>Hoa hồng</th> */}
                    <th className='min-w-100px'>Trạng thái</th>
                    <th className='min-w-150px'>Thời gian</th>
                    {isAdmin && <th className='min-w-150px text-center rounded-end'>Thao tác</th>}
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {dataTable?.map((value: any, index: number) => {
                    return (
                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
                              >
                                {value?.customer?.name}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                          >
                            {value?.customer?.mobile}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                          >
                            {value?.customer?.email}
                          </a>
                        </td>

                        {isAdmin && (
                          <>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <a
                                  href='#'
                                  className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
                                >
                                  {value?.distributor?.name}
                                </a>
                                <span className='text-muted fw-bold text-muted d-block fs-7'>
                                  {value?.distributor?.mobile || value?.distributor?.email}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
                              >
                                {value?.aff?.name}
                              </a>
                              <span className='text-muted fw-bold text-muted d-block fs-7'>
                                {value?.aff?.mobile || value?.aff?.email}
                              </span>
                            </div>
                          </div>
                        </td>
                        </>
                        )}

                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                          >
                            {value?.packageData?.name}
                          </a>
                        </td>

                        {/* <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                          >
                            {formatMoney(value?.total_price)}
                          </a>
                        </td> */}

                        {/* <td>
                          <span className='badge badge-light-primary fs-7 fw-bold'>
                            {formatMoney(value?.commission)}
                          </span>
                        </td> */}
                        <td>
                          <span
                            className={`badge fs-7 fw-bold ${
                              value?.status === 1
                                ? 'badge-light-success'
                                : value?.status === 0
                                ? 'badge-light-primary'
                                : 'badge-light-danger'
                            }`}
                          >
                            {value?.status === 1
                              ? 'Thành công'
                              : value?.status === 0
                              ? 'Chờ xử lý'
                              : 'Thất bại'}
                          </span>
                        </td>

                        <td>
                          <span className='fs-7 fw-bold'>{formatTime(value?.createdAt)}</span>
                        </td>

                        {isAdmin && value?.status === 0 && (
                          <td className='text-end d-flex justify-content-center'>
                            <Tooltip title='Xác nhận' placement='bottom'>
                              {' '}
                              <button
                                onClick={() => showPropsConfirmSuccess(value)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art006.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                            </Tooltip>
                            <Tooltip title='Huỷ bỏ' placement='bottom'>
                              {' '}
                              <button
                                onClick={() => showPropsConfirmExit(value)}
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                            </Tooltip>
                          </td>
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </Skeleton>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </div>
  )
}

export default SubmitCourse
