import {http} from 'src/setup/axios/SetupAxios'

export const searchOrder = async (params: any) => {
  try {
    const dataValue = await http.get(`aff/orders`, {
      params
    })
    return dataValue?.data
  } catch (error) {
    return error
  }
}

export const searchAffChild = async (params: any) => {
  try {
    const dataValue = await http.get(`aff/child`, {
      params
    })
    return dataValue?.data
  } catch (error) {
    return error
  }
}

export const getPackage = async (params: any) => {
  try {
    const dataValue = await http.get(`products/packages`, {
      params: {
        ...(params || {}),
        status: 1
      }
    })
    return dataValue?.data
  } catch (error) {
    return error
  }
}

export const getInfoCommission = async () => {
  try {
    const dataValue = await http.get(`aff/info-commission`)
    return dataValue?.data
  } catch (error) {
    return error
  }
}

export const getBalanceCommission = async () => {
  try {
    const dataValue = await http.get(`aff/balance-commission`)
    return dataValue?.data
  } catch (error) {
    return error
  }
}

export const getPayCommission = async () => {
  try {
    const dataValue = await http.get(`aff/history-pay-commission`)
    return dataValue?.data
  } catch (error) {
    return error
  }
}

export const getTopRevenue = async (params: any) => {
  try {
    const dataValue = await http.get(`aff/top-revenue`, {
      params
    })
    return dataValue?.data
  } catch (error) {
    return error
  }
}

export const getAffCommission = async () => {
  try {
    const dataValue = await http.get(`aff/aff-commission`)
    return dataValue?.data
  } catch (error) {
    return error
  }
}



