import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_URL_API || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}auth/get-user`
export const LOGIN_URL = `${API_URL}auth/login`
export const REGISTER_URL = `${API_URL}auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}auth/forgot-aff`
export const REQUEST_RESET_PASSWORD_URL = `${API_URL}auth/verify-forgot-aff`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {email, password})
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<any>(REQUEST_PASSWORD_URL, {email})
}

export function requestResetPassword(otp: string, password: string, token: string) {
  return axios.post<any>(REQUEST_RESET_PASSWORD_URL, {otp, password, token})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
