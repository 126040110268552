/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import {Link, useParams} from 'react-router-dom'
import {toAbsoluteUrl, KTSVG} from '../../../../_metronic/helpers'
import {ForgotPassword} from './ForgotPassword'
import {Modal} from 'react-bootstrap-v5'
import {register} from '../../../../setup/redux/service/loginService'
import {message} from 'antd'
import {http} from 'src/setup/axios/SetupAxios'
import {useNavigate} from 'react-router-dom'

const initialValues = {
  fullName: '',
  phone: '',
  referral_phone: '',
  email: '',
  password: '',
  changepassword: '',
}

const registrationSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Vui lòng nhập họ tên'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Vui lòng nhập Email'),
  phone: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Vui lòng nhập số điện thoại'),

  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Vui lòng nhập mật khẩu'),
  changepassword: Yup.string()
    .required('Vui lòng nhập mật khẩu xác nhận')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Mật khẩu xác nhận không khớp'),
    }),
  // acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingOtp, setLoadingOtp] = useState<boolean>(false)
  const [isModal, setIsModal] = useState<boolean>(false)
  const [code, setCode] = useState<string>('')
  const [token, setToken] = useState<string>('')
  const [emailRef, setEmail] = useState<string>('')

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      let ref_code = '';
      if (window.AIVA_AFF) {
        ref_code = window.AIVA_AFF.getAffCode();
      }
      try {
        const dataPush: {} = {
          email: values?.email,
          password: values.changepassword,
          name: values.fullName,
          mobile: values.phone,
          ref_code: values?.referral_phone || ref_code,
        }
        const res: any = await register(dataPush)
        if (res?.data?.code !== 200) {
          message.error(res?.data?.message)
        }
        setToken(res?.data?.data?.token)
        setEmail(values?.email)
        setLoading(false)
        setSubmitting(false)
        setIsModal(true)
      } catch (error) {
        return error
      }
    },
  })

  useEffect(() => {
    setTimeout(() => {
      if (window.AIVA_AFF) {
        const ref_code = window.AIVA_AFF.getAffCode();
        formik.setFieldValue("referral_phone", ref_code)
      }
    }, 1000)
  }, [])

  const handleSendCode = async () => {
    try {
      setLoadingOtp(true)
      const res = await http.post('/auth/verify-register-aff', {
        otp: code,
        token: token,
      })
      if (res?.data?.code !== 200) {
        setLoadingOtp(false)
        return message.error('Thất bại !')
      }
      localStorage.setItem('token', res?.data?.data?.token)
      message.success('Đăng ký thành công, đang đăng nhập lại ...')
      setTimeout(() => {
        navigate('/dashboard')
      }, 1500)
      setLoadingOtp(false)
    } catch (error) {
      setLoadingOtp(false)
      return message.error('Thất bại !')
    }
  }

  return (
    <>
      <Modal
        className='modal fade css_config'
        id='kt_modal_select_location'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={isModal}
        dialogClassName='modal-lg'
        centered
      >
        <div className='bg-white rounded shadow-sm p-20 p-lg-15 mx-auto m-15'>
          <div className='row fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6 text-center'>Nhập mã OTP được gửi tới email <br/> {emailRef}</label>
            <input
              placeholder='Nhập mã OTP'
              type='text'
              autoComplete='off'
              className='form-control form-control-lg form-control-solid'
              onChange={(e: any) => setCode(e.target.value)}
            />
          </div>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            onClick={handleSendCode}
          >
            {!loadingOtp && <span className='indicator-label'>Xác nhận</span>}
            {loadingOtp && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>

          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
            onClick={() => setIsModal(false)}
          >
            Huỷ
          </button>
        </div>
      </Modal>
      <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
        {' '}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework '
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            {/* begin::Heading */}
            <div className='mb-10 text-center'>
              {/* begin::Title */}
              <h1 className='text-dark mb-3'>Đăng ký tài khoản Affiliate</h1>
              {/* end::Title */}

              {/* begin::Link */}
              <div className='text-gray-400 fw-bold fs-4'>
                Đã có tài khoản?
                <Link
                  to='/auth/login'
                  className='link-primary fw-bolder'
                  style={{marginLeft: '5px'}}
                >
                  Đăng nhập ?
                </Link>
              </div>
              {/* end::Link */}
            </div>
            {/* end::Heading */}

            {/* begin::Action */}

            {/* end::Action */}

            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            {/* begin::Form group Firstname */}
            <div className='row fv-row mb-7'>
              <label className='form-label fw-bolder text-dark fs-6'>Họ tên</label>
              <input
                placeholder='Nhập Họ tên'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('fullName')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.fullName && formik.errors.fullName,
                  },
                  {
                    'is-valid': formik.touched.fullName && !formik.errors.fullName,
                  }
                )}
              />
              {formik.touched.fullName && formik.errors.fullName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.fullName}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='row fv-row mb-7'>
              <label className='form-label fw-bolder text-dark fs-6'>Số điện thoại</label>
              <input
                placeholder='Nhập SĐT'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('phone')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.phone && formik.errors.phone,
                  },
                  {
                    'is-valid': formik.touched.phone && !formik.errors.phone,
                  }
                )}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.phone}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='row fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>
              Mã Đại lý (nếu có)
            </label>
            <input
              placeholder=''
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('referral_phone')}
              className={clsx(
                'form-control form-control-lg form-control-solid'
              )}
            />
            {formik.touched.referral_phone && formik.errors.referral_phone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.referral_phone}</span>
                </div>
              </div>
            )}
          </div>
            {/* end::Form group */}

            {/* begin::Form group Email */}
            <div className='fv-row mb-7'>
              <label className='form-label fw-bolder text-dark fs-6'>Email</label>
              <input
                placeholder='Email'
                type='email'
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Password */}
            <div className='mb-10 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bolder text-dark fs-6'>Mật khẩu</label>
                <div className='position-relative mb-3'>
                  <input
                    type='password'
                    placeholder=''
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>Xác nhận mât khẩu</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('changepassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                  },
                  {
                    'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                  }
                )}
              />
              {formik.touched.changepassword && formik.errors.changepassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.changepassword}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            {/* <div className='fv-row mb-10'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='kt_login_toc_agree'
                {...formik.getFieldProps('acceptTerms')}
              />
              <label
                className='form-check-label fw-bold text-gray-700 fs-6'
                htmlFor='kt_login_toc_agree'
              >
                I Agree the{' '}
                <Link to='/auth/terms' className='ms-1 link-primary'>
                  terms and conditions
                </Link>
                .
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.acceptTerms}</span>
                  </div>
                </div>
              )}
            </div>
          </div> */}
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Đăng ký</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_signup_form_cancel_button'
                  className='btn btn-lg btn-light-primary w-100 mb-5'
                >
                  Huỷ
                </button>
              </Link>
            </div>
            {/* end::Form group */}
          </form>
        </div>
      </div>
    </>
  )
}
